 .card {
   border: none;
   /* border-radius: 20px; */
   box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
   margin: 10px;
 }

 .carousel-inner {
   padding: 1em;
 }

 .carousel-control-prev,
 .carousel-control-next {
   background-color: #e1e1e1;
   width: 6vh;
   height: 6vh;
   border-radius: 50%;
   top: 50%;
   transform: translateY(-50%);
 }

 .carousel-control-prev span,
 .carousel-control-next span {
   width: 1.5rem;
   height: 1.5rem;
 }

 @media screen and (min-width: 577px) {
   .cards-wrapper {
     display: flex;
   }

   /* .card {
        margin: 0 0.5em;
        width: calc(100% / 2);
    } */

   .image-wrapper {
     height: auto;
     margin: 0 auto;
   }
 }

 @media screen and (max-width: 576px) {
   .card:not(:first-child) {
     display: none;
   }
 }

 .image-wrapper img {
   width: 100%;
   height: 20vh;
   aspect-ratio: 3/2;
   object-fit: contain;
   align-items: center;



 }

 .not_for_pc {
   display: none;
 }

 .not_for_mobile {
   display: block;
 }

 @media screen and (max-width: 800px) {
   .not_for_pc {
     display: block;
   }

   .not_for_mobile {
     display: none;
   }
 }

 .testo_img {
   /* margin-left: 30px; */
   width: 80%;
   aspect-ratio: 3/2;
   object-fit: contain;
   background-color: white;
   padding: 3px;
   border-radius: 10px;
 }

 .client_img {
   width: 100%;
   aspect-ratio: 3/2;
   object-fit: contain;
   background-color: white;
   padding: 0px;
   /* border-radius: 80px; */
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
   /* padding: 10px 0;
    margin: 10px 0px;  */
 }

 #cards .card_content {

   margin: 20px 30px;
   padding: 20px 20px;
   border-radius: 4px;
   /* height: 45vh; */
   background-color: #222327;
   position: relative;
   transition: all 0.25s ease-in-out;
 }

 @media (max-width:600px) {
   #cards .card_content {

     margin: 20px 0px;
     padding: 40px 30px;
     background-color: #222327;
     position: relative;
     transition: all 0.25s ease-in-out;
   }

 }

 #cards .card_content .card__icon {
   width: 100%;
   margin-bottom: 40px;
 }

 #cards .card_content .card__icon img {
   height: 75px;
   background-color: rgba(255, 255, 255, 0.93);
   border-radius: 5px;
   padding: 7px 10px;
 }

 #cards .card_content h6 {
   margin-bottom: 10px;
   color: #ffffff;
   font-size: 16px;
   font-weight: 600;
   letter-spacing: 0.5px;
 }

 #cards .card_content ul li {
   list-style: none;
   padding: 3px 0;
   color: #ffffff;
   font-size: 16px;
   font-weight: 300;
   opacity: 0.7;
 }

 /*   
  #cards .card_content.selected::before {
    content: "";
    width: 50%;
    height: 50%;
    position: absolute;
    right: -25px;
    bottom: -30px;
    background: url(https://i.imgur.com/WunqBPz.png);
    background-position: right;
    background-size: 10px;
    z-index: -1;
  } */

 #cards .card_content.selected {
   background-color: #161619;
   box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.3);
 }


 .swiper {
   width: 100%;
   /* height: 40vh; */
 }

 .swiper-slide {
   text-align: center;
   font-size: 18px;
   background: var(--client-card-back);
   border-radius: 5px;
   display: flex;

   /* Center slide text vertically */
   display: flex;
   justify-content: center;
   align-items: center;
 }

 /* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
 .client-logo {
   width: 100%;
   aspect-ratio: 3/2;
   object-fit: contain;
 }

 /* OutleadNavigationBar.css */

 .OutleadNavigationBar {
   position: sticky;
   width: 100%;
   /* top: 0; */
   left: 0;
   /* height: 70px; */
   background-color: transparent;
   /* Background color for the navbar */
   transition: all 0.6s ease-in-out;
   transform: translatey(10px);
   /* Smooth transition effect */
   z-index: 10;
   /* Ensure the navbar is above other elements */
 }

 .OutleadNavigationBar.sticky {
   position: sticky;
   width: 100%;
   top: 0;
   left: 0;
   /* height: 70px; */
   transition: all 0.6s ease-in-out;
   /* Smooth transition effect */
   z-index: 10;
   background-color: rgba(0, 0, 0, 0.498);
   backdrop-filter: blur(20px);
   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
   transform: translatey(00px);
   /* Optional shadow for sticky effect */
 }

 #navbar {
   background-color: transparent;
   /* color: white; */
 }

 .light-mode {
   background-color: rgb(254, 254, 254);
   --home-main-heading: black;
   --navbar-color-change: rgb(0, 0, 0);
   --section-title: black;
   --para-color: rgb(47, 47, 47);
   --client-card-back: rgb(239, 239, 239);
   --explore-btn: black;
   --type-back: rgb(244, 244, 244);
   --about-us-main-heading: black;
   --about-para: rgb(39, 39, 39);
   --double-color: black;
   --title-section-title: rgb(0, 0, 0);
   --testomonial-heading: black;
   --testomonial-back: white;
   --services-icon: black;
   --choose-us-back: #f0f0f0;
   --contact-card-back: #22252b;
   --switch-back-color: rgb(238, 238, 238);
   --see-more: black;
   --about-back-outlead: rgb(239, 239, 239);
   transition: 0.5s;
   --back-color: rgb(255, 255, 255);
   --LinksColor: black;
 }

 .dark-mode {
   background: #23272e;
   --home-main-heading: rgb(255, 255, 255);
   --navbar-color-change: rgb(255, 255, 255);
   --section-title: white;
   --para-color: rgb(195, 195, 195);
   --client-card-back: rgb(255, 255, 255);
   --explore-btn: rgb(0, 0, 0);
   --type-back: rgb(255, 255, 255);
   --about-us-main-heading: rgb(255, 255, 255);
   --about-para: rgb(255, 255, 255);
   --double-color: rgb(255, 255, 255);
   --title-section-title: rgb(255, 255, 255);
   --testomonial-heading: white;
   --testomonial-back: #282c34;
   --services-icon: white;
   --choose-us-back: #22252b;
   --contact-card-back: black;
   --switch-back-color: #22252b;
   --about-back-outlead: #22252b;
   --see-more: white;
   --back-color: black;
   --LinksColor: white;
   transition: 0.5s;

 }

 #navbar .nav-link {
   color: var(--LinksColor);
 }

 .sticky #navbar {
   background-color: rgb(33, 37, 41);
 }

 .sticky #navbar .nav-link {
   color: white
 }

 .navbar-toggler {
   color: white;
 }

 @media (max-width:769px) {
   #navbar .nav-link {
     color: white;
   }

   #navbar {
     background-color: rgb(33, 37, 41);
     color: white;
   }

   /* #navbar .nav-link {
     color: white;
   } */

   .sticky #navbar {
     background-color: rgb(33, 37, 41);
   }

   /* .sticky #navbar .nav-link {
     color: white;
   } */

   .navbar-toggler {
    color: white;
   }

   .OutleadNavigationBar {
     position: sticky;
     width: 100%;
     /* top: 0; */
     left: 0;
     /* height: 70px; */
     background-color: transparent;
     /* Background color for the navbar */
     transition: all 0.6s ease-in-out;
     transform: translatey(0px);
     /* Smooth transition effect */
     z-index: 10;
     /* Ensure the navbar is above other elements */
   }

 }

 .about_content p {
   color: var(--para-color);
 }