@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,500&display=swap");

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* .about_content {
  padding-top: 3rem;
} */

.opacity {
  font-size: 8rem;
  color: rgb(122, 122, 122);
  position: absolute;
  margin-top: -40px;
  opacity: 0.3;
  z-index: -2;

}

p {
  color: var(--para-color);
}

.slitely-space {
  padding: 1rem 0rem;
}

.about_content .double_colour {
  color: var(--double-color);
  font-size: 2.3rem;
  text-align: left;

}

.about_content .double_colour span {
  color: orange;
  font-size: 2.3rem;
  text-align: left;

}

.about_content p {
  color: var(--about-para);
  width: 100%;

  /* font-weight: 500; */
  /* padding: 1rem 2rem; */
}

@media (max-width:600px) {

  .opacity {
    font-size: 5rem;
    color: rgb(122, 122, 122);
    position: absolute;
    margin-top: -40px;
    opacity: 0.3;
    z-index: -2;

  }

  .about_content {
    padding: 3rem 0.5rem;
  }
}

/* Navbar css */
*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
#navbar{
  position:fixed;
  width: 100%;
  z-index: 1000000;
  padding: 0px ;
  
}
@media (max-width:600px){
 
}
/* .html{
    font-size: 62.5%;
} */

/* .navbar input[type="checkbox"],
.navbar .hamburger-lines{
    display: none;
}

.container{
    max-width: 1200px;
    width: 90%;
    margin: auto;
}

.navbar{
    box-shadow: 0px 5px 10px 0px #aaa;
    position: fixed;
    width: 100%;
    background: linear-gradient(45deg, #a700ff , #4d19db);
    color: #000;
    opacity: 0.85;
    z-index: 2;
}

.navbar-container{
    display: flex;
    justify-content: space-between;
    height: 64px;
    align-items: center;
}

.menu-items{
    order: 2;
    display: flex;
}
.logo{
    order: 1;
    font-size: 2.3rem;
}

.menu-items li{
    list-style: none;
    margin-left: 1.5rem;
    font-size: 1.3rem;
}

.navbar a{
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-weight: 500;
    
}

.navbar a:hover{
    color: #b46919;
}


@media (max-width: 768px){
    .navbar{
        opacity: 0.95;
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines{
        display: block;
    }

    .navbar-container{
        display: block;
        position: relative;
        height: 64px;
    }

    .navbar-container input[type="checkbox"]{
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }

    .navbar-container .hamburger-lines{
        display: block;
        height: 28px;
        width: 35px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line{
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #333;
    }
    
    .navbar-container .hamburger-lines .line1{
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2{
        transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3{
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar .menu-items{
        padding-top: 100px;
        background: #fff;
        height: 100vh;
        max-width: 300px;
        transform: translate(-150%);
        display: flex;
        flex-direction: column;
        margin-left: -40px;
        padding-left: 40px;
        transition: transform 0.5s ease-in-out;
        box-shadow:  5px 0px 10px 0px #aaa;
        overflow: scroll;
    }

    .navbar .menu-items li{
        margin-bottom: 1.8rem;
        font-size: 1.1rem;
        font-weight: 500;
    }

    .logo{
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 2.5rem;
    }

    .navbar-container input[type="checkbox"]:checked ~ .menu-items{
        transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1{
        transform: rotate(45deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2{
        transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3{
        transform: rotate(-45deg);
    }

}


.navbar a:hover span {
  transform: translate3d(0, -2px, 0);
}

@media (max-width: 500px){
    .navbar-container input[type="checkbox"]:checked ~ .logo{
        display: none;
    }
} */
/* navbar css */
@font-face {
  src: url("./poppins.ttf");
  font-family: "Poppins";
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: "Poppins";
}

.light-mode {
  background-color: rgb(254, 254, 254);
  --home-main-heading: black;
  --navbar-color-change: rgb(0, 0, 0);
  --section-title: black;
  --para-color: rgb(47, 47, 47);
  --client-card-back: rgb(239, 239, 239);
  --explore-btn: black;
  --type-back: rgb(244, 244, 244);
  --about-us-main-heading: black;
  --about-para: rgb(39, 39, 39);
  --double-color: black;
  --title-section-title: rgb(0, 0, 0);
  --testomonial-heading: black;
  --testomonial-back: white;
  --services-icon: black;
  --choose-us-back: #f0f0f0;
  --contact-card-back: #22252b;
  --switch-back-color: rgb(238, 238, 238);
  --see-more: black;
  --about-back-outlead: rgb(239, 239, 239);
  transition: 0.5s;
  --back-color:rgb(255, 255, 255);
  --LinksColor:black;
}

.dark-mode {
  background: #23272e;
  --home-main-heading: rgb(255, 255, 255);
  --navbar-color-change: rgb(255, 255, 255);
  --section-title: white;
  --para-color: rgb(195, 195, 195);
  --client-card-back: rgb(255, 255, 255);
  --explore-btn: rgb(0, 0, 0);
  --type-back: rgb(255, 255, 255);
  --about-us-main-heading: rgb(255, 255, 255);
  --about-para: rgb(255, 255, 255);
  --double-color: rgb(255, 255, 255);
  --title-section-title: rgb(255, 255, 255);
  --testomonial-heading: white;
  --testomonial-back: #282c34;
  --services-icon: white;
  --choose-us-back: #22252b;
  --contact-card-back: black;
  --switch-back-color: #22252b;
  --about-back-outlead: #22252b;
  --see-more: white;
  --back-color:black;
  --LinksColor:white
  transition: 0.5s;

}

.testomonial-heading {
  color: var(--testomonial-heading);
}

.navbar-change {
  color: var(--navbar-color-change);

}

/* body {
  background: #282c34;
} */

.section-padding {
  padding: 3rem 0rem;

}

/* img {
  width: 100%;
} */

header {
  width: 80%;
  height: 70px;
  background: #fefefe;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  border-radius: 0 0 10px 10px;
  margin: 0 auto;
}

.logo {
  width: 120px;
  margin-top: 8px;
}

/* nav .logo {
  display: none;
}

nav ul {
  display: flex;
}

nav ul li a {
  color: #212526;
  display: block;
  margin: 0 2px;
  font-weight: 600;
  padding: 8px 18px;
  transition: 0.2s;
  border-radius: 30px;
  text-decoration: none;
}

nav ul li a:hover {
  background: #f6f4ff;
}

nav ul li a.active {
  color: #fff;
  background: orangefn;
}

.hamburger {
  display: none;
  height: fit-content;
  cursor: pointer;
  padding: 3px 8px;
  border-radius: 5px;
  transition: 0.2s;
}

.hamburger:hover {
  background: #f6f4ff;
}

.hamburger .line {
  width: 30px;
  height: 2px;
  margin: 6px 0;
  background: #212526;
}

@media only screen and (max-width: 1100px) {
  header {
    width: 90%;
    padding: 0 20px;
  }

  nav {
    position: absolute;
    left: -300px;
    top: 0;
    z-index: 999;
    width: 280px;
    height: 100vh;
    background-color: #282C34;
    transition: 0.2s;
    box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.05);
  }

  #nav_check:checked~nav {
    left: 0;
  }

  nav .logo {
    display: block;
    height: 70px;
    display: flex;
    align-items: center;
    margin-left: 30px;
  }

  nav ul {
    display: block;
    padding: 0 20px;
    margin-top: 30px;
  }

  nav ul li a {
    margin-bottom: 5px;
    padding: 15px;
    border-radius: 5px;
  }

  .hamburger {
    display: block;
  }
} */

/* Home page css */
.start {
  margin: 0;
  padding: 0;
  font-family: "Poopins sans-serief";
}

.hero-main-heading {
  color: var(--home-main-heading);
}

.hero {
  width: 100%;
  margin-bottom: 80px;
  /* background: linear-gradient(45deg, #a700ff, #4d19db);
  background: #282c34; */
  padding-left: 4%;
  padding-right: 4%;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  z-index: 1;
}
@media(max-width:769px){
  .hero {
    width: 100%;
    margin-bottom: 40px;
    /* background: linear-gradient(45deg, #a700ff, #4d19db);
    background: #282c34; */
    padding-left: 4%;
    padding-right: 4%;
    box-sizing: border-box;
    color: #fff;
    position: relative;
    z-index: 1;
  }
}

/* .trianglr1{
  position: absolute;
  left: 50%;
  top
} */
.GAQ {
  border: 1px solid orange;
  padding: 10px 30px;
  border-radius: 5px;
  color: var(--explore-btn);
  text-decoration: none;
  background-color: #EFEFEF;
  /* width: 100%; */
}

.GAQ:hover {
  background-color: orange;
  color: #fff;
}

.col-2 .GAQ {
  margin-left: 0;
  margin-right: 30px;
  background: #282c34;
  color: #fff;
  font-weight: 500;
}

.col-2 .GAQ-mob {
  border: 1px solid orange;
  padding: 10px 30px;
  border-radius: 5px;
  margin-left: 0;
  margin-right: 30px;
  background: orange;
  color: #fff;
  font-weight: 500;
}

.col-2 .GAQ-mob:hover {
  border: 1px solid orange;
  padding: 10px 30px;
  border-radius: 5px;
  margin-left: 0;

  background: rgb(200, 130, 0);
  color: #fff;
  font-weight: 500;
}

.col-2 .GAQ:hover {
  background-color: orange;
  color: #fff;
}

/* .nav{
  display: flex;
  align-items: center;
  padding: 30px 0;
}
.logo{
  width: 150px;
}
.nav ul{
  flex: 1;
  text-align: right;
  
}
nav ul li{
  display: inline-block;
  list-style: none;
  margin: 0 15px;
}
a{
  text-decoration: noen;
  color: #fff;
} */
.rowtemp {
  display: flex;
  flex-wrap: wrap;
  padding-top: 200px;
  justify-content: space-evenly;
}

.col-1 {
  flex-basis: 40%;
  min-width: 300px;
  margin-bottom: 30px;
  position: relative;
}

.col-2 {
  flex-basis: 55%;
  min-width: 300px;
  margin-bottom: 30px;
}

.col-1 img {
  width: 100%;
}

.col-2 h1 {
  font-size: 50px;
  font-weight: 600;
}

.col-2 {
  margin: 10px 0 50px;
  max-width: 580px;
}

.col-2 a {
  text-decoration: none;
}

.elements {
  position: absolute;
  left: 0;
  top: 0;
  animation: move 2.5s linear infinite;
}

@keyframes move {
  0% {
    transform: translate(-15px, 0px);
  }

  50% {
    transform: translate(0, -15px);
  }

  100% {
    transform: translate(-15px, 0px);
  }
}

.homePageGif1 {
  padding-bottom: 129%;
  position: absolute;
}

.card:hover {
  transform: scale(1.1);
}

.card {
  transition: transform 0.5s;
}

.card {
  margin: 10px auto;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  height: 100%;
  transition: 0.3s;
  background-color: aliceblue;
}

.card:hover {
  box-shadow: 1px 10px 16px 0 rgba(0, 0, 0, 0.3);
}

.card-title {
  text-align: center;
  color: #666666;
  font-weight: bold;
}

.card-text {
  font-weight: 500;
  color: #66686b;
}

.card-image {
  text-align: center;
  margin: 5vh 0;
}

.card-text {
  text-align: center;
}

.title-heading {
  font-size: 3em;
  text-align: center;
  margin: 4%;
  font-weight: bold;
}

.headingtext h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.headingtext h1:before {
  background-color: #c50000;
  content: "";
  display: block;
  height: 3px;
  width: 75px;
  margin-bottom: 5px;
}

.headingtext h1:after {
  background-color: #c50000;
  content: "";
  display: block;
  position: absolute;
  left: 120px;
  height: 3px;
  width: 75px;
  margin-bottom: 0.25em;
}

.textt {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.lottie-animated {
  width: 50%;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}

.head {
  color: orange;
}

.whatwedo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nine h1 {
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
}

.nine h1 span {
  margin-top: 5px;
  font-size: 15px;
  color: var(--section-title);
  word-spacing: 1px;
  font-weight: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-weight: 500;

  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
  align-items: center;
}

.nine h1 span:after,
.nine h1 span:before {
  content: " ";
  display: block;
  border-bottom: 1px solid orange;
  border-top: 1px solid orange;
  height: 5px;
  background-color: orange;
}

.nine h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #fff;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

/* client carousel css */
.containerClient {
  margin: 0;
  text-align: center;
  font-family: sans-serif;
}

.containerClient {
  position: relative;
  width: 320px;
  margin: 100px auto 0 auto;
  perspective: 1000px;
}

.carousell {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: rotate360 60s infinite forwards linear;
}

.carousel__facee {
  position: absolute;
  width: 300px;
  height: 187px;
  top: 20px;
  left: 10px;
  right: 10px;

  /* box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5); */
  display: flex;
}

.containerClient span {
  margin: auto;
  font-size: 2rem;
}

.carousel__facee:nth-child(1) {
  background-image: url("../src/assets/img/Hitechlogo.png");
  background-size: 300px 187px;
  background-repeat: no-repeat;
  background-color: #282c34;
  /* border-radius: 40%; */
  transform: rotateY(0deg) translateZ(400px);
}

.carousel__facee:nth-child(2) {
  background-image: url("../src/assets/img/client2png.png");
  transform: rotateY(40deg) translateZ(400px);
  background-size: 300px 187px;
  background-repeat: no-repeat;
  background-color: #282c34;
  /* border-radius: 40%; */
}

.carousel__facee:nth-child(3) {
  background-image: url("../src/assets/img/client3.png");
  transform: rotateY(80deg) translateZ(400px);
  background-size: 300px 187px;
  background-repeat: no-repeat;
  background-color: #282c34;
  /* border-radius: 40%; */
}

.carousel__facee:nth-child(4) {
  background-image: url("../src/assets/img/client4.png");
  transform: rotateY(120deg) translateZ(400px);
  background-size: 300px 187px;
  background-repeat: no-repeat;
  background-color: #282c34;
  /* border-radius: 40%; */
}

.carousel__facee:nth-child(5) {
  background-image: url("../src/assets/img/client5.png");
  transform: rotateY(160deg) translateZ(400px);
  background-size: 300px 187px;
  background-repeat: no-repeat;
  background-color: #282c34;
  /* border-radius: 40%; */
}

.carousel__facee:nth-child(6) {
  background-image: url("../src/assets/img/client6.png");
  transform: rotateY(200deg) translateZ(400px);
  background-size: 300px 187px;
  background-repeat: no-repeat;
  background-color: #282c34;
  /* border-radius: 40%; */
}

.carousel__facee:nth-child(7) {
  background-image: url("../src/assets/img/client7.png");
  transform: rotateY(240deg) translateZ(400px);
  background-size: 300px 187px;
  background-repeat: no-repeat;
  /* background-shadow: 10px 10px #212526 */
  background-size: cover;
  /* background-color: #080808; */
  background-color: #282c34;
  /* border-radius: 20%; */
}

.carousel__facee:nth-child(8) {
  background-image: url("../src/assets/img/client8.png");
  transform: rotateY(280deg) translateZ(400px);
  background-size: 300px 187px;
  background-repeat: no-repeat;
  background-color: #282c34;
}

.carousel__facee:nth-child(9) {
  background-image: url("../src/assets/img/client6.png");
  transform: rotateY(320deg) translateZ(400px);
  background-size: 300px 187px;
  background-repeat: no-repeat;
  background-color: #282c34;
}

.content {
  font-family: "Poppins", sans-serif;
  line-height: 1.6;
  word-wrap: break-word;
}

#content {
  padding: 10px 20px;
  height: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}

@media (max-width:900px) {
  #content {
    padding: 0px 0px;
    margin-top: 40px
  }
}

/* Testimonial css */
/* ========== 00. General Styling ========== */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

* {
  box-sizing: border-box;
  outline: 0;
  transition: all 0.3s ease;
}

/* body {
    margin: 0;
    margin-top: 60px;
    padding: 0;
    font-family: "Open Sans", "Noto Sans", HelveticaNeueCyr, Helvetica, sans-serif;
    line-height: 1.68em;
    color: #333333;
    background: #fafafa;
} */

blockquote {
  padding: 0;
  margin: 0;
}

section.t-bq-section {
  padding: 30px;
  margin-bottom: 60px;
}

.t-bq-wrapper.t-bq-wrapper-boxed {
  max-width: 576px;
  margin: 0 auto;
}

.t-bq-wrapper.t-bq-wrapper-fullwidth {
  max-width: 100%;
}

/* ========== 05. Paul ========== */

.t-bq-quote-paul {
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 250px;
  box-shadow: 2px 2px 25px orange;
  border-radius: 10px;
}

.t-bq-quote-paul .t-bq-quote-paul-pattern {
  flex-basis: 80px;
  background: url("https://raw.githubusercontent.com/taviskaron/t-div-blockquotes/main/img/hexabump.png") repeat;
  border-radius: 10px 0 0 10px;
}

.t-bq-quote-paul .t-bq-quote-paul-base {
  flex-basis: calc(100% - 80px);
  background: #ffffff;
  padding: 40px 30px 50px 80px;
  font-size: 11pt;
  line-height: 1.62em;
  border-radius: 0 10px 10px 0;
}

.t-bq-quote-paul .t-bq-quote-paul-qmark {
  position: absolute;
  top: 50px;
  left: 105px;
  font-family: Garamond, Georgia, "Times New Roman", serif;
  font-size: 42pt;
  color: #999999;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.t-bq-quote-paul .t-bq-quote-paul-userpic {
  position: absolute;
  top: 80px;
  left: 35px;
  width: 80px;
  height: 70px;
  background: url("https://raw.githubusercontent.com/taviskaron/t-div-blockquotes/main/img/strug.jpg") center center no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.t-bq-quote-paul .t-bq-quote-paul-meta {
  margin-top: 30px;
  padding-top: 10px;
  border-top: 2px dotted #777777;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-author,
.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-source {
  color: #777777;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-author {
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-size: 10pt;
  font-weight: bold;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-author cite {
  font-style: normal;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-source {
  font-size: 9pt;
}

@media screen and (max-width: 768px) {
  .t-bq-quote-paul .t-bq-quote-paul-pattern {
    flex-basis: 20px;
  }

  .t-bq-quote-paul .t-bq-quote-paul-base {
    flex-basis: calc(100% - 20px);
    padding: 100px 30px 50px 30px;
  }

  .t-bq-quote-paul .t-bq-quote-paul-userpic {
    width: 80px;
    height: 50px;
    left: 40px;
    top: 20px;
  }

  .t-bq-quote-paul .t-bq-quote-paul-qmark {
    left: 100px;
    top: 45px;
  }
}

@media screen and (max-width: 768px) {
  .t-bq-quote-jasper .t-bq-quote-jasper-base {
    padding-left: 30px;
  }
}

@keyframes rotate360 {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(-360deg);
  }
}

/* Footer css */
footer {
  padding-top: 2rem;
  padding-bottom: 0px;
}

.footer-07 {
  background: rgb(33, 37, 41);
}



.footer-07 p {
  color: rgba(255, 255, 255, 0.3);
}

.footer-07 .footer-heading {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 30px;
}

.footer-07 .footer-heading .logo {
  color: #fff;
}

.footer-07 .menu {
  margin-bottom: 10px;
}

.footer-07 .menu a {
  color: rgba(255, 255, 255, 0.6);
  margin: 0 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.ftco-footer-social li {
  list-style: none;
  margin: 0 10px 0 0;
  display: inline-block;
}

.ftco-footer-social li a {
  height: 40px;
  width: 40px;
  display: block;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  position: relative;
}

.ftco-footer-social li a span {
  position: absolute;
  font-size: 20px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ftco-footer-social li a:hover {
  color: #fff;
}

.form-control {
  height: 52px;
  background: #fff;
  color: #000;
  font-size: 18px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.form-control:focus,
.form-control:active {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

textarea.form-control {
  height: inherit !important;
}

.ftco-section {
  padding: 12em 0;
}

.ftco-section h2 {
  margin-bottom: 0;
}

footer a {
  text-decoration: none;
}

footer i.fa {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0 0 2px orange;
  padding: 0.5em 0.6em;
}
footer i.fa:hover{
  transform: translatey(-5px);
  transition: .5s;
}

/* contactCeo css */


#contact {
  background-color: transparent;
}

#contact .back_col {

  width: 50%;
  height: 70vh;

}

/* 
#contact .absolute {

  position: absolute;
  left: 20%;

  right: 20%;
  bottom: 20%;
} */
#contact .contact_content button {
  background-color: orange;
  border-radius: 5px;
  border: none;
  padding: 7px 30px;
  color: white;
  margin-top: 15px;
}

/* #contact .absolute .col {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #43484f;
  margin: 20px;
  height: 50vh;
} */
#contact .animate_img {
  width: 30%;
  margin: auto;

}

#contact .back_col:nth-child(2) {
  background: url("../public/Sources/images/contact.jpg");
  object-fit: contain;
  width: 100%;
  background-size: cover;
}

.back i {
  display: inline-block;
  border-radius: 60px;

  padding: 0.5em 0.6em;
}

/* .front,
.back {
  box-sizing: border-box;
  padding: 2rem;
  padding-top: 0px;
  border-radius: 4px;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.25);
  font-family: BlinkMacSystemFont, "Segoe UI", sans-serif;
  font-size: 2rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background-color: #1f2124;
} */

/* front side bg is green gradient */
.front {
  background-color: #1f2124;
}

/* back side bg is orange gradient */
/* .back {
  background-color: #1f2124;
} */

.card-container {
  position: relative;
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  overflow: hidden;
  transition: transform 0.8s ease;
}

.back {
  transform: rotateY(180deg);
}

.card-container:hover .front {
  transform: rotateY(-180deg);
}

.card-container:hover .back {
  transform: rotateY(0deg);
}

.card-container {
  perspective: 75rem;
}

.forCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .banner-contact {
  height: 400px;
  width: 100%;
  background-image: url("/src//assets/img/test.jpg");
  opacity: 0.5;
} */

.front h4 {
  font-family: "Poppins", sans-serif;
}

.check-list {
  margin: 0;
  padding-left: 1.2rem;
}

.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 2.5rem;
  margin-bottom: 0.5rem;
  font-size: medium;
  text-align: left;
  font-family: "Poppins";
}

.check-list li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -2px;
  width: 5px;
  height: 11px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: #00a8a8;
  transform-origin: bottom left;
  transform: rotate(45deg);
}



/* ---------------------about section start --------------------------*/

.aboutpage {
  padding: 4rem 0rem;
}

.aboutHeading {
  font-weight: 900;
  font-size: 3.2rem;
  color: var(--about-us-main-heading);
}

.aboutHeading span {
  font-weight: 900;
  font-size: 3.2rem;
  color: orange;
}

.abouthr {
  border: 2px solid orange;
  width: 10%;
  opacity: 1;
}

.img_fluid {
  border-radius: 20px;
  padding: 0px 30px;
  width: 100%;

}

.about_two {

  background-color: var(--about_two);

}

/* ---------------------about section End --------------------------*/
/* About page css */
/* @media only screen and (min-width: 600px) {
  .aboutpage {
    background-image: url("../src/assets/img/bgcontact.png");
    width: 100%; 
    height: 85vh;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding-top: 4rem;
    background-position: center;
  }


  .abouthr {
    border: 2px solid orange;
    width: 10%;
    opacity: 1;
  }
  .contentofAbout {
    height: 50em;
    width: 40em;
    padding-left: 64px;
    text-align: left;
    font-family: Poppins;
    font-size: large;
  }
  .service {
    margin-left: "150px";
    margin-right: "150px";
  }
  .op-laptop {
    margin-top: 120px;
  }
  .row-laptop {
    margin-top: 150px;
  }
} */

@media only screen and (max-width: 600px) {
  /* .aboutpage {
    background-color: #fff;
    background-image: url("../src/assets/img/bgcontactmobile.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 10px;
    height: 100%;
    width: auto;
  } */

  /* .aboutHeading {
    font-weight: 900;
    font-size: 1.5rem;
    padding-top: 40px;
    padding-left: 20px;
  } */

  /* .abouthr {
    border: 2px solid red;
    width: 20%;
    margin-left: 20px;
  } */

  /* .contentofAbout {
    height: 50em;
    width: 28em;
    padding-left: 10px;
    text-align: left;
    font-family: Poppins;
    font-size: small;
  } */
  /* .service {
    margin-left: "50px";
    margin-right: "50px";
  }
  .op-mobile {
    margin-top: 10px;
  }
  .row-mobile {
    margin-top: 20px;
  } */
}

/* .GAQ {
  margin-left: 0;
  margin-right: 30px;
  background: #282c34;
  color: #fff;
  font-weight: 500;
}

.GAQ:hover {
  background-color: orange;
  color: #fff;
} */

/* service css */

:root {
  --primary: #6c55f9;
  --accent: #ff3d85;
  --secondary: #645f88;
  --success: #35bb78;
  --info: #05b4e1;
  --warning: #fac14d;
  --danger: #ff4943;
  --grey: #b4b2c5;
  --dark: #2d2b3a;
  --light: #f6f5fc;
}

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap");

.text-xs {
  font-size: 12px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-md {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 18px !important;
}

.text-xl {
  font-size: 20px !important;
}

/* Color systems */
.bg-primary {
  background-color: #6c55f9 !important;
}

a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #5d47eb !important;
}

.bg-accent {
  background-color: #ff3d85 !important;
}

a.bg-accent:hover,
a.bg-accent:focus {
  background-color: #e93577 !important;
}

.bg-secondary {
  background-color: #645f88 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus {
  background-color: #59547c !important;
}

.bg-success {
  background-color: #35bb78 !important;
}

a.bg-success:hover,
a.bg-success:focus {
  background-color: #28a868 !important;
}

.bg-info {
  background-color: #05b4e1 !important;
}

a.bg-info:hover,
a.bg-info:focus {
  background-color: #07a2c8 !important;
}

.bg-warning {
  background-color: #fac14d !important;
}

a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #ebb039 !important;
}

.bg-danger {
  background-color: #ff4943 !important;
}

a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #e73832 !important;
}

.bg-grey {
  background-color: #8e8aad !important;
}

a.bg-grey:hover,
a.bg-grey:focus {
  background-color: #7d7a99 !important;
}

.bg-light {
  background-color: #f6f5fc !important;
}

a.bg-light:hover,
a.bg-light:focus {
  background-color: #edecf5 !important;
}

/* .bg-dark {
  background-color: #2D2B3A !important;
}

a.bg-dark:hover, a.bg-dark:focus {
  background-color: #1d1b25 !important;
} */
.text-center {
  margin-left: auto;
  margin-right: auto;
}

.text-primary {
  color: #6c55f9 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #5d47eb !important;
}

.text-accent {
  color: #ff3d85 !important;
}

a.text-accent:hover,
a.text-accent:focus {
  color: #e93577 !important;
}

.text-secondary {
  color: #645f88 !important;
}

/* a.text-secondary:hover, a.text-secondary:focus {
  color: #59547c !important;
} */

.text-success {
  color: #35bb78 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #28a868 !important;
}

.text-info {
  color: #05b4e1 !important;
}

/* 
a.text-info:hover, a.text-info:focus {
  color: #07a2c8 !important;
} */

.text-warning {
  color: #fac14d !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ebb039 !important;
}

.text-danger {
  color: #ff4943 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #e73832 !important;
}

.text-grey {
  color: #8e8aad !important;
}

a.text-grey:hover,
a.text-grey:focus {
  color: #7d7a99 !important;
}

.text-light {
  color: #f6f5fc !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #edecf5 !important;
}

.text-dark {
  color: #2d2b3a !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #1d1b25 !important;
}

.text-body {
  color: #3f3d4d !important;
}

.border {
  border-color: #e9e8f5 !important;
}

.border-top {
  border-top-color: #e9e8f5 !important;
}

.border-right {
  border-right-color: #e9e8f5 !important;
}

.border-bottom {
  border-bottom-color: #e9e8f5 !important;
}

.border-left {
  border-left-color: #e9e8f5 !important;
}

.border-primary {
  border-color: #6c55f9 !important;
}

.border-accent {
  border-color: #ff3d85 !important;
}

.border-secondary {
  border-color: #645f88 !important;
}

.border-success {
  border-color: #35bb78 !important;
}

.border-info {
  border-color: #05b4e1 !important;
}

.border-warning {
  border-color: #fac14d !important;
}

.border-danger {
  border-color: #ff4943 !important;
}

.border-grey {
  border-color: #b4b2c5 !important;
}

.border-light {
  border-color: #f6f5fc !important;
}

.border-dark {
  border-color: #2d2b3a !important;
}

/* Social Color */
.bg-facebook,
.bg-hover-facebook:hover,
.bg-focus-facebook:focus {
  background-color: #3b5999 !important;
}

.bg-twitter,
.bg-hover-twitter:hover,
.bg-focus-twitter:focus {
  background-color: #1da1f2 !important;
}

.bg-google-plus,
.bg-hover-google-plus:hover,
.bg-focus-google-plus:focus {
  background-color: #db4437 !important;
}

.bg-youtube,
.bg-hover-youtube:hover,
.bg-focus-youtube:focus {
  background-color: #cd201f !important;
}

.bg-dribbble,
.bg-hover-dribbble:hover,
.bg-focus-dribbble:focus {
  background-color: #ea4c89 !important;
}

.bg-pinterest,
.bg-hover-pinterest:hover,
.bg-focus-pinterest:focus {
  background-color: #bd081c !important;
}

.bg-slack,
.bg-hover-slack:hover,
.bg-focus-slack:focus {
  background-color: #3aaf85 !important;
}

.bg-linkedin,
.bg-hover-linkedin:hover,
.bg-focus-linkedin:focus {
  background-color: #0077b5 !important;
}

.fg-facebook,
.fg-hover-facebook:hover,
.fg-focus-facebook:focus {
  color: #3b5999 !important;
}

.fg-twitter,
.fg-hover-twitter:hover,
.fg-focus-twitter:focus {
  color: #1da1f2 !important;
}

.fg-google-plus,
.fg-hover-google-plus:hover,
.fg-focus-google-plus:focus {
  color: #db4437 !important;
}

.fg-youtube,
.fg-hover-youtube:hover,
.fg-focus-youtube:focus {
  color: #cd201f !important;
}

.fg-dribbble,
.fg-hover-dribbble:hover,
.fg-focus-dribbble:focus {
  color: #ea4c89 !important;
}

.fg-pinterest,
.fg-hover-pinterest:hover,
.fg-focus-pinterest:focus {
  color: #bd081c !important;
}

.fg-slack,
.fg-hover-slack:hover,
.fg-focus-slack:focus {
  color: #3aaf85 !important;
}

.fg-linkedin,
.fg-hover-linkedin:hover,
.fg-focus-linkedin:focus {
  color: #0077b5 !important;
}

.btn.social-facebook {
  background-color: #3b5999;
  color: #fff;
}

.btn.social-facebook:hover,
.btn.social-facebook:focus {
  background-color: #314e8f;
  color: #fff;
}

.btn.social-twitter {
  background-color: #1da1f2;
  color: #fff;
}

.btn.social-twitter:hover,
.btn.social-twitter:focus {
  background-color: #0d92e4;
  color: #fff;
}

.btn.social-linkedin {
  background-color: #0077b5;
  color: #fff;
}

.btn.social-linkedin:hover,
.btn.social-linkedin:focus {
  background-color: #02659b;
  color: #fff;
}

.btn.google-plus {
  background-color: #db4437;
  color: #fff;
}

.btn.google-plus:hover,
.btn.google-plus:focus {
  background-color: #ca3224;
  color: #fff;
}

/* Buttons */
.btn {
  transition: all 0.2s ease;
}

.btn:focus {
  box-shadow: none !important;
}

/* .btn-primary {
  color: #fff;
  background-color: #6C55F9;
  border-color: transparent;
} */
/* 
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #5d47eb;
  border-color: transparent;
} */

/* .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #6C55F9;
  border-color: #5d47eb;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
} */

.btn-accent {
  color: #fff;
  background-color: #ff3d85;
  border-color: transparent;
}

.btn-accent.disabled,
.btn-accent:disabled {
  color: #fff;
  background-color: #e93577;
  border-color: transparent;
}

.btn-accent:not(:disabled):not(.disabled):active,
.btn-accent:not(:disabled):not(.disabled).active,
.show>.btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #ff3d85;
  border-color: #e93577;
}

.btn-accent:not(:disabled):not(.disabled):active:focus,
.btn-accent:not(:disabled):not(.disabled).active:focus,
.show>.btn-accent.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary {
  color: #fff;
  background-color: #645f88;
  border-color: transparent;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #59547c;
  border-color: transparent;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #645f88;
  border-color: #59547c;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success {
  color: #fff;
  background-color: #35bb78;
  border-color: transparent;
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a868;
  border-color: transparent;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #35bb78;
  border-color: #28a868;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-info {
  color: #fff;
  background-color: #05b4e1;
  border-color: transparent;
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #07a2c8;
  border-color: transparent;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #05b4e1;
  border-color: #07a2c8;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-warning {
  color: #343531;
  background-color: #fac14d;
  border-color: transparent;
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #343531;
  background-color: #ebb039;
  border-color: transparent;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: #343531;
  background-color: #fac14d;
  border-color: #ebb039;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-danger {
  color: #fff;
  background-color: #ff4943;
  border-color: transparent;
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #e73832;
  border-color: transparent;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff4943;
  border-color: #e73832;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-light {
  color: #8e8aad;
  background-color: #f5f9f6;
  border-color: transparent;
}

.btn-light:hover {
  color: #8e8aad;
  background-color: #f6f5fc;
  border-color: transparent;
}

.btn-light:focus,
.btn-light.focus {
  color: #8e8aad;
  background-color: #d5dfdc;
  border-color: transparent;
  box-shadow: none;
}

.btn-light.disabled,
.btn-light:disabled {
  color: #8e8aad;
  background-color: #d0ddd9;
  border-color: transparent;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  color: #8e8aad;
  background-color: #f5f9f6;
  border-color: #d0ddd9;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-dark {
  color: #fff;
  background-color: #2d2b3a;
  border-color: transparent;
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #1d1b25;
  border-color: transparent;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #2d2b3a;
  border-color: #1d1b25;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary:hover,
.btn-accent:hover,
.btn-secondary:hover,
.btn-success:hover,
.btn-info:hover,
.btn-warning:hover,
.btn-danger:hover,
.btn-dark:hover {
  color: #fff;
  background-color: #1d1b25;
  border-color: transparent;
}

.btn-primary:focus,
.btn-primary.focus,
.btn-accent:focus,
.btn-accent.focus,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-success:focus,
.btn-success.focus,
.btn-info:focus,
.btn-info.focus,
.btn-warning:focus,
.btn-warning.focus,
.btn-danger:focus,
.btn-danger.focus,
.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #8e8aad;
  border-color: transparent;
  box-shadow: none;
}

.btn-outline {
  border-color: #d7d5e9;
  color: var(--see-more);
}

.btn-outline:hover {
  background-color: #fff;
  color: #212529;
}

.btn-outline-primary {
  color: #6c55f9;
  border-color: #6c55f9;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #6c55f9;
  border-color: #6c55f9;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #6c55f9;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #6c55f9;
  border-color: #6c55f9;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-accent {
  color: #ff3d85;
  border-color: #ff3d85;
}

.btn-outline-accent:hover {
  color: #fff;
  background-color: #ff3d85;
  border-color: #ff3d85;
}

.btn-outline-accent:focus,
.btn-outline-accent.focus {
  box-shadow: none;
}

.btn-outline-accent.disabled,
.btn-outline-accent:disabled {
  color: #ff3d85;
  background-color: transparent;
}

.btn-outline-accent:not(:disabled):not(.disabled):active,
.btn-outline-accent:not(:disabled):not(.disabled).active,
.show>.btn-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #ff3d85;
  border-color: #ff3d85;
}

.btn-outline-accent:not(:disabled):not(.disabled):active:focus,
.btn-outline-accent:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-accent.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-secondary {
  color: #645f88;
  border-color: #645f88;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #645f88;
  border-color: #645f88;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: none;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #645f88;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #645f88;
  border-color: #645f88;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success {
  color: #35bb78;
  border-color: #35bb78;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #35bb78;
  border-color: #35bb78;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: none;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #35bb78;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #35bb78;
  border-color: #35bb78;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #05b4e1;
  border-color: #05b4e1;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #05b4e1;
  border-color: #05b4e1;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: none;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #05b4e1;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #05b4e1;
  border-color: #05b4e1;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #fac14d;
  border-color: #fac14d;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #fac14d;
  border-color: #fac14d;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: none;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #fac14d;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #fac14d;
  border-color: #fac14d;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #ff4943;
  border-color: #ff4943;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff4943;
  border-color: #ff4943;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: none;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #ff4943;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff4943;
  border-color: #ff4943;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #f6f5fc;
  border-color: #f6f5fc;
}

.btn-outline-light:hover {
  color: #343531;
  background-color: #f6f5fc;
  border-color: #f6f5fc;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: none;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f6f5fc;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
  color: #343531;
  background-color: #f6f5fc;
  border-color: #f6f5fc;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-dark {
  color: #2d2b3a;
  border-color: #2d2b3a;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #2d2b3a;
  border-color: #2d2b3a;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: none;
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #2d2b3a;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #2d2b3a;
  border-color: #2d2b3a;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-link {
  font-weight: 400;
  color: #5d47eb;
  text-decoration: none;
}

.btn-link:hover {
  color: #5641df;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #645f88;
  pointer-events: none;
}

.btn {
  padding: 8px 24px;
}

.breadcrumb {
  font-weight: 500;
  background-color: #f8f9fa;
}

.breadcrumb .breadcrumb-item.active {
  color: #8e8aad;
}

.breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  color: #8e8aad;
}

.breadcrumb-dark .breadcrumb-item a {
  color: #6c55f9;
}

.breadcrumb-dark .breadcrumb-item a:hover {
  color: #6c55f9;
  text-decoration: none;
}

.breadcrumb-dark .breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: rgba(255, 255, 255, 0.8);
  content: "/";
}

.breadcrumb-dark .breadcrumb-item.active {
  color: rgba(255, 255, 255, 0.8);
}
.navbar-nav {
  padding-left: 20px;
  padding-top: 30px;
}
/* 
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav {
    margin-top: 0;
    border-top: none;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }
  .navbar-expand-sm .navbar-nav {
    align-items: center;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav {
    margin-top: 0;
    border-top: none;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }
  .navbar-expand-md .navbar-nav {
    align-items: center;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    margin-top: 0;
    border-top: none;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }
  .navbar-expand-lg .navbar-nav {
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav {
    margin-top: 0;
    border-top: none;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 16px;
    padding-left: 16px;
  }
  .navbar-expand-xl .navbar-nav {
    align-items: center;
  }
} */
.navbar-expand-lg .navbar-nav{
  padding: 0px;
}
@media (max-width:769px){
  .navbar-expand-lg .navbar-nav{
    padding-left: 30px;
  }
}
.form-control {
  padding: 8px 15px;
  height: calc(1.5em + 1.375rem + 2px);
  border-color: #d6dbd9;
}

.custom-select {
  height: calc(1.5em + 1.375rem + 2px);
}

.page-link {
  margin-left: 5px;
  min-width: 40px;
  color: #b4b2c5;
  border: 1px solid #dee1e6;
  text-align: center;
  border-radius: 4px;
}

.page-link:hover {
  color: #645f88;
  background-color: #f6f5fc;
  border-color: #dee1e6;
}

.page-link:focus {
  box-shadow: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #6c55f9;
  border-color: #4330c2;
}

.page-item.disabled .page-link {
  color: #645f88;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.img-place {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
}

.img-place img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.bg-image {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-image>* {
  position: relative;
  z-index: 10;
}

.bg-image-parallax {
  background-attachment: fixed;
}

.bg-image-overlay-dark {
  position: relative;
}

.bg-image-overlay-dark::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: #343531;
}

.bg-size-50 {
  background-size: 50% 50%;
}

.bg-size-75 {
  background-size: 75% 75%;
}

.bg-size-100 {
  background-size: 100% 100%;
}

.avatar {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.avatar-img {
  margin-right: 6px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-img img {
  width: 100%;
  height: 100%;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(224, 223, 233, 0.7);
  visibility: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 1100;
}

.back-to-top::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -4px auto;
  width: 12px;
  height: 12px;
  border-top: 2px solid #555;
  border-right: 2px solid #555;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.back-to-top:hover {
  background: #6c55f9;
}

.back-to-top:hover::after {
  border-color: #fff;
}

.page-section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 0px;

  overflow: hidden;
}

.page-section p {
  color: var(--para-color);
}

.services-back-theme {
  background-color: var(--page-section-back);
}

.page-banner {
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 320px;
  background-color: #f6f5fc;
  color: #645f88;
  border-radius: 30px;
}

.page-banner.home-banner {
  margin-top: 100px;
  height: auto;
  background-color: #fff;
  border-radius: 0;
}

.page-banner.home-banner h1 {
  color: #2d2b3a;
}

.page-banner.home-banner .img-place {
  max-width: 400px;
  margin: 0 auto;
}

.page-banner.home-banner .btn-scroll {
  position: absolute;
  bottom: -14px;
  left: 0;
  right: 0;
  margin: auto;
  width: 26px;
  height: 40px;
  line-height: 44px;
  background-color: #fff;
  color: #645f88;
  text-align: center;
  border-radius: 40px;
  box-shadow: 0 2px 6px rgba(100, 95, 136, 0.24);
  transition: all 0.2s ease;
}

.page-banner.home-banner .btn-scroll:hover {
  text-decoration: none;
  background-color: #6c55f9;
  color: #fff;
}

@media (min-width: 768px) {
  .page-banner.home-banner {
    height: 600px;
  }
}

@media (min-width: 992px) {
  .page-banner.home-banner {
    margin-top: 0;
  }
}

.page-hero.overlay-dark::before,
.page-banner.overlay-dark::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 53, 49, 0.7);
  z-index: 1;
}

.page-banner .breadcrumb-item,
.page-banner .breadcrumb-item a {
  font-size: 14px;
}

.home-banner .row>*:first-child {
  padding-left: 8%;
}

.subhead {
  display: block;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #898798;
  font-weight: 500;
  margin-bottom: 8px;
}

.title-section {
  max-width: 450px;
  color: var(--title-section-title);

  font-weight: 600;
}

.title-section-2 {
  max-width: 450px;
  color: black;
  font-weight: 600;
}

.title-section .marked {
  position: relative;
  color: orange;
}

.title-section-2 .marked {
  position: relative;
  color: orange;
}

.title-section .marked::before {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 8px;
  /* background-color: #d3ccff; */
  z-index: -1;
}

.title-section-2 .marked::before {
  content: "";
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  height: 8px;
  /* background-color: #d3ccff; */
  z-index: -1;
}

.text-center .title-section {
  margin-left: auto;
  margin-right: auto;
}

.text-center .title-section-2 {
  margin-left: auto;
  margin-right: auto;
}

.divider {
  display: block;
  margin-top: 16px;
  margin-bottom: 32px;
  width: 62px;
  height: 3px;
  border-radius: 40px;
  background-color: orange;
}

.btn-split {
  display: inline-flex;
  align-items: center;
  border-radius: 40px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 6px;
}

.btn-split .fab {
  display: inline-block;
  margin-left: 12px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 32px;
  text-align: center;
  background-color: #fff;
  color: #6c55f9;
}

.card-service {
  display: block;
  margin: 16px auto;
  padding: 32px 20px;
  max-width: 300px;
  text-align: center;
  color: #898798;
  border-radius: 8px;
  box-shadow: 0 3px 12px rgba(95, 92, 120, 0.12);
}

.card-service .header {
  margin-bottom: 24px;
}

.img-stack-element {
  position: relative;
  text-align: center;
}

.img-stack-element svg {
  display: inline-block;
  max-width: 460px;
}

@media (min-width: 992px) {
  .img-stack-element {
    transform: translateX(50px);
  }
}

.features {
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 1px solid #e9e8f5;
  border-bottom: 1px solid #e9e8f5;
}

.features .container {
  max-width: 980px;
}

.features h5 {
  color: #2d2b3a;
}

.features p {
  font-size: 13px;
  color: #898798;
}

.counter-section .row>*:first-child {
  border-radius: 6px 0 0 6px;
}

.counter-section .row>*:last-child {
  border-radius: 0 6px 6px 0;
}

.counter-section .row>* {
  padding-top: 64px;
  padding-bottom: 64px;
  border: 1px solid #e9e8f5;
  margin-left: -1px;
}

.counter-section p {
  margin-bottom: 6px;
  color: #b4b2c5;
}

.card-pricing {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 32px 20px;
  max-width: 300px;
  border: 1px solid #e9e8f5;
  border-radius: 6px;
  text-align: center;
  overflow: hidden;
}

.card-pricing .price-labled {
  position: absolute;
  top: 16px;
  right: -30px;
  width: 120px;
  height: 26px;
  line-height: 26px;
  background-color: #0ac7f6;
  color: #fff;
  transform: rotate(45deg);
}

.card-pricing .header {
  color: #b4b2c5;
}

.card-pricing .price-icon {
  font-size: 75px;
  line-height: 1;
}

.card-pricing .price-title {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 20px;
}

.card-pricing .price-tag .currency {
  display: inline-block;
  transform: translateY(-30px);
}

.card-pricing .price-tag .currency,
.card-pricing .price-tag .period {
  font-weight: 600;
  font-size: 14px;
}

.card-pricing .price-tag h2 {
  display: inline-block;
}

.card-pricing.active {
  background-color: #6c55f9;
  border-color: #5d47eb;
}

.card-pricing.active .header {
  color: rgba(255, 255, 255, 0.5);
}

.card-pricing.active .price-title {
  color: #fac14d;
}

.card-pricing.active .price-tag {
  color: #fff;
}

.card-pricing.active .price-info p {
  color: rgba(255, 255, 255, 0.75);
}

.card-pricing.active .btn-outline {
  color: #fff;
}

.card-pricing.active .btn-outline:hover {
  color: #6c55f9;
}

.card-blog {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 32px 20px 70px 20px;
  max-width: 260px;
  min-height: 270px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(100, 95, 136, 0.16);
}

.card-blog .header {
  margin-bottom: 16px;
}

.card-blog .avatar {
  position: relative;
  display: inline-block;
  margin-right: 6px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #d3d0e4;
  overflow: hidden;
}

.card-blog .avatar img {
  width: 100%;
  height: 100%;
}

.card-blog .entry-footer {
  display: inline-block;
  vertical-align: top;
}

.card-blog .post-author {
  font-weight: 500;
  color: #645f88;
}

.card-blog .post-date {
  font-size: 13px;
  color: #b4b2c5;
}

.card-blog .post-title {
  margin-bottom: 8px;
  font-size: 18px;
}

.card-blog .post-title a {
  font-weight: 500;
  color: #2d2b3a;
}

.card-blog .post-excerpt {
  font-size: 14px;
  color: #898798;
}

.card-blog .footer {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.card-blog-row *:first-child .card-blog {
  background-color: #6c55f9;
}

.card-blog-row *:first-child .card-blog .post-author,
.card-blog-row *:first-child .card-blog .post-title a,
.card-blog-row *:first-child .card-blog .footer a {
  color: #fff;
}

.card-blog-row *:first-child .card-blog .post-date {
  color: rgba(255, 255, 255, 0.6);
}

.form-search-blog .custom-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.testi-image {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 300px;
  height: 280px;
  background-color: #645f88;
  border-radius: 8px;
  overflow: hidden;
}

.testi-image img {
  width: 100%;
  height: auto;
}

.testi-content {
  font-size: 20px;
}

.testi-content .entry-footer {
  margin-top: 24px;
  font-size: 15px;
}

.client-section {
  padding: 64px 0;
  background-color: #f6f5fc;
}

.client-section .item {
  padding: 16px 0;
  text-align: center;
}

.contact-list {
  position: relative;
  padding-left: 0;
  list-style: none;
}

.contact-list li {
  margin-bottom: 12px;
}

.contact-list .icon {
  display: inline-block;
  margin-right: 6px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #d7d5e9;
  color: #6c55f9;
}

.contact-list .content {
  display: inline-block;
  color: #8e8aad;
}

.contact-list .content a {
  color: #8e8aad;
}

.page-footer {
  position: relative;
  display: block;
  padding-top: 80px;
  padding-bottom: 16px;
  background-color: #fff;
  border-top: 1px solid #e9e8f5;
}

.page-footer p a {
  transition: color 0.2s ease;
}

.page-footer h3,
.page-footer h4,
.page-footer h5 {
  color: #645f88;
  margin-bottom: 24px;
  font-weight: 600;
}

.page-footer p {
  color: #898798;
}

.footer-menu {
  position: relative;
  padding-left: 0;
  list-style: none;
}

.footer-menu li {
  margin-bottom: 12px;
}

.footer-link {
  display: inline-block;
  padding: 6px 0;
}

.footer-menu a,
.footer-link {
  color: #898798;
}

.footer-menu a:hover,
.footer-link:hover {
  color: #6c55f9;
  text-decoration: none;
}

.sosmed-button a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 44px;
  text-align: center;
  border-radius: 50%;
  background-color: #f6f5fc;
  color: #898798;
  transition: all 0.2s linear;
}

.sosmed-button a:hover {
  background-color: #6c55f9;
  color: #fff;
  text-decoration: none;
  transform: rotate(360deg);
}

.blog-single-wrap {
  display: block;
  padding: 50px 0;
}

.blog-single-wrap .header {
  margin-bottom: 32px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 12px rgba(100, 95, 136, 0.2);
  overflow: hidden;
}

.blog-single-wrap .header .post-thumb {
  width: 100%;
  height: 250px;
  background-color: #f6f5fc;
  overflow: hidden;
}

.blog-single-wrap .header .post-thumb img {
  width: 100%;
}

.blog-single-wrap .header .meta-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  height: 50px;
  transform: translateY(-48px);
}

.blog-single-wrap .header .post-author .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid #fff;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(137, 135, 152, 0.4);
  transform: translateY(10px);
}

.blog-single-wrap .header .post-author .avatar img {
  width: 100%;
  height: 100%;
}

.blog-single-wrap .header .post-sharer a {
  padding: 5px 10px;
  line-height: 1;
  box-shadow: none !important;
}

.blog-single-wrap .header .post-sharer a[class="btn"] {
  background-color: #ec9d1e;
  color: #fff;
}

.blog-single-wrap .header .post-sharer a[class="btn"]:hover {
  background-color: #d88d14;
  color: #fff;
}

.blog-single-wrap .post-title {
  font-weight: 500;
  color: #212529;
}

.blog-single-wrap .post-meta {
  display: block;
  margin-bottom: 24px;
}

.blog-single-wrap .post-meta .icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  background-color: #645f88;
  color: #fff;
}

.blog-single-wrap .post-meta .post-date,
.blog-single-wrap .post-meta .post-comment-count {
  display: inline-block;
}

.blog-single-wrap .post-meta .post-date a,
.blog-single-wrap .post-meta .post-comment-count a {
  color: #645f88;
}

.blog-single-wrap .post-content .quote {
  display: block;
  padding: 16px 20px;
  background-color: #6c55f9;
  color: #fff;
  font-size: 18px;
  border-radius: 8px;
}

.blog-single-wrap .post-content .quote .author {
  display: block;
  margin-top: 16px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.widget-box {
  display: block;
  padding: 20px;
  margin-bottom: 32px;
  border-radius: 6px;
  border: 1px solid #edecf5;
  box-shadow: 0 3px 9px rgba(100, 95, 136, 0.15);
}

.widget-title {
  color: #6c55f9;
}

.search-widget .form-control {
  margin-bottom: 8px;
  background-color: #f6f5fc;
  border-color: transparent;
  box-shadow: none !important;
}

.search-widget .form-control:focus {
  border-color: #dee1e6;
}

.search-widget .btn {
  text-transform: uppercase;
}

.categories {
  position: relative;
  list-style: none;
  padding-left: 0;
}

.categories li a {
  display: block;
  padding: 6px;
  color: #a4a1c8;
  transition: all 0.2s ease;
}

.categories li a:hover {
  color: #6c55f9;
  text-decoration: none;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(107, 85, 249, 0.5);
}

.blog-item {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e4e7ee;
}

.blog-item .post-thumb {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  width: 100px;
  height: 80px;
  background-color: #f6f5fc;
  overflow: hidden;
}

.blog-item .post-thumb img {
  width: auto;
  height: 100%;
}

.blog-item .post-title a {
  color: #8e8aad;
  transition: all 0.2s ease;
}

.blog-item .post-title a:hover {
  color: #6c55f9;
  text-decoration: none;
}

.blog-item .meta a {
  margin-right: 6px;
  font-size: 12px;
  color: #645f88;
}

.blog-item .meta a:hover {
  text-decoration: none;
}

.tag-cloud-link {
  display: inline-block;
  margin-bottom: 6px;
  padding: 4px 10px;
  font-size: 13px;
  background-color: #edecf5;
  color: #645f88;
  border-radius: 3px;
  transition: all 0.2s ease;
}

.tag-cloud-link:hover {
  background-color: #645f88;
  color: #fff;
  text-decoration: none;
}

.maps-container {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #f6f5fc;
  overflow: hidden;
}

#google-maps {
  width: 100%;
  height: 100%;
}

.custom-img-1 {
  width: auto;
  max-width: 390px;
}

.custom-index {
  z-index: 11;
}

/* Custom Plugin */
.owl-nav {
  display: block;
  margin: 15px auto;
  text-align: center;
}

/* 
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  display: inline-block;
  padding: 6px 0 !important;
  background-color: #6C55F9;
  color: #fff;
}

.owl-carousel .owl-nav button.owl-next {
  padding-right: 14px !important;
  padding-left: 7px !important;
  border-radius: 0 40px 40px 0;
}

.owl-carousel .owl-nav button.owl-prev {
  padding-right: 7px !important;
  padding-left: 14px !important;
  border-radius: 40px 0 0 40px;
} */

.owl-carousel .owl-dots {
  display: block;
  margin: 16px auto;
  text-align: center;
}

.owl-carousel button.owl-dot {
  display: inline-block;
  margin: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #aba7c2;
  transition: all 0.2s ease;
}

.owl-carousel button.owl-dot:focus {
  outline: none;
}

.owl-carousel button.owl-dot.active {
  background-color: #6c55f9;
}

/* .services {
  background-color: #1d1b25;
} */

/* .services_overlay{
  background-color: rgba(255, 255, 255, 0.5);
  height: 40vh;

 
} */
/* css of blog website */
/*

Sided Template

https://templatemo.com/tm-527-sided



    Primary color         #99ccff
    Primary bright color  #3399FF
    Gray bg               #f6f6f6
    Dark Gray bg          #eeeeee
*/

.tm-bg-white-alpha {
  background-color: rgba(255, 255, 255, 0.5);
}

.tm-bg-black-alpha {
  background-color: rgba(0, 0, 0, 0.5);
}

.tm-bg-gray {
  background-color: #f6f6f6;
}

.tm-bg-dark-gray {
  background-color: #eeeeee;
}

h2 {
  font-size: 1.4rem;
}

p {
  line-height: 1.7;
}

.tm-text-white {
  color: white;
}

.columns-bg {
  background-image: linear-gradient(to right,
      #282c34,
      #282c34 50%,
      #ffffff 50%);
}

.columns-bg a {
  text-decoration: none;
}

.container-fluid {
  max-width: 1310px;
}

.tm-section-logo {
  padding-top: 170px;
  padding-bottom: 0;
}

.tm-section-logo-short {
  padding-top: 100px;
}

.tm-p-1-section-1 {
  margin-bottom: 80px;
}

#our_place {
  padding-top: 70px;
  padding-bottom: 70px;
}

.tm-section-4 {
  padding-top: 70px;
  padding-bottom: 70px;
}

#gallery {
  padding-top: 115px;
  padding-bottom: 110px;
}

.tm-section-contact {
  padding-top: 110px;
  padding-bottom: 90px;
}

#outro {
  padding-top: 90px;
}

#footer {
  padding-top: 30px;
  padding-bottom: 55px;
}

.tm-bg-primary {
  background-color: #99ccff;
}

.tm-text-primary {
  color: orange;
}

.tm-text-primary-bright {
  color: #3399ff;
}

.tm-hr-primary-bright {
  border-color: #3399ff;
}

.tm-hr-white {
  border-color: white;
}

.tm-text-secondary {
  color: #999999;
}

.tm-text-gray {
  color: #656565;
}

.tm-text-accent {
  color: #3698cc;
}

.tm-section-title-2 {
  font-size: 1.8rem;
  text-align: right;
}

.tm-section-title-underline {
  border-top: 1px solid black;
  max-width: 500px;
  margin-right: 0;
  width: 85%;
}

.tm-site-name-container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #282c35;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tm-site-name-container-inner {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.tm-site-name {
  font-size: 3.5rem;
  font-weight: 600;
}

.tm-site-description {
  font-size: 1.3rem;
  margin-bottom: 50px;
}

.tm-section-text-container {
  padding: 0 50px;
}

.tm-section-text-container-2 {
  padding: 20px 55px 30px;
  max-width: 640px;
}

.tm-section-text-container-3 {
  padding: 64px 68px;
}

.tm-section-col-4 {
  margin-right: 0;
  margin-left: auto;
}

.tm-section-text-container-4 {
  padding: 40px 55px 0;
}

.tm-footer-text-container {
  padding: 0 70px;
}

.tm-section-text-container-5 {
  max-width: 430px;
  padding-right: 85px;
  margin-right: 0;
  margin-left: auto;
}

.tm-section-text-container-6 {
  padding: 0 55px 60px;
}

.tm-section-text-container-8 {
  padding: 64px 68px;
}

.tm-section-title-mb {
  margin-bottom: 30px;
}

.tm-section-title-mb-2 {
  margin-bottom: 50px;
}

.tm-img-right {
  margin-left: -1px;
  /* to fix 1 px gap in Chrome */
}

/* .tm-img-left-container,
.tm-text-left-container {
  padding-right: 0;
}

.tm-img-right-container {
  padding-left: 0;
} */

/* Common style */
.grid {
  overflow: auto;
  display: flex;
  align-items: baseline;
  margin-left: auto;
  margin-right: auto;
}

.grid figure {
  position: relative;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  text-transform: uppercase;
  /* font-size: 1.25em; */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption>a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption>a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 800;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/*---------------*/
/***** Duke *****/
/*---------------*/

figure.effect-duke {
  background: -webkit-linear-gradient(-45deg, #34495e 0%, #cc6055 100%);
  background: linear-gradient(-45deg, #34495e 0%, #cc6055 100%);
}

figure.effect-duke img,
figure.effect-duke p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-duke:hover img {
  opacity: 0.1;
  -webkit-transform: scale3d(2, 2, 1);
  transform: scale3d(2, 2, 1);
}

figure.effect-duke h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

figure.effect-duke p {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
  padding: 30px;
  border: 2px solid #fff;
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transform-origin: 50% -100%;
  transform-origin: 50% -100%;
}

figure.effect-duke:hover h2,
figure.effect-duke:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.tm-contact-section {
  font-size: 0.95rem;
}

.tm-contact-left {
  max-width: 450px;
  margin-left: auto;
  margin-right: 0;
  padding: 70px 45px 70px 70px;
}

.tm-contact-left,
.tm-contact-form-container {
  max-width: 450px;
}

.tm-contact-link {
  color: #313638;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.tm-contact-icon-container {
  display: inline-block;
  background-color: white;
  color: #202122;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 30px;
}

.tm-contact-icon-container-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tm-social-icon-container {
  cursor: pointer;
  width: 62px;
  height: 62px;
  background-color: #99ccff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

.tm-social-icon-container-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tm-contact-icon {
  font-size: 1.5rem;
}

.tm-phone-icon {
  margin-top: -2px;
  margin-left: -2px;
}

.tm-contact-form {
  max-width: 380px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  border-color: #9a9a9a;
  font-size: 0.9rem;
}

.form-control:focus {
  box-shadow: none;
}

.tm-contact-form-container {
  padding: 55px 67px;
}

.tm-btn-submit {
  border: 1px solid #9a9a9a;
  padding: 10px 35px;
  color: #313638;
  font-size: 0.9rem;
}

.tm-btn-submit:hover {
  color: #99ccff;
  border-color: #99ccff;
}

.tm-social-icon-container:hover {
  background-color: #568dc0;
}

.tm-social-icon-container i {
  color: white;
}

.tm-copyright-text {
  font-size: 0.85rem;
}

.tm-copyright-link {
  color: #99ccff;
}

.tm-p-2-section-1 {
  margin-top: 70px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.tm-p-3-section-1 {
  padding-top: 82px;
}

.tm-p-3-section-3 {
  margin-top: 60px;
  margin-bottom: 40px;
}

.tm-p-3-section-contact {
  padding-top: 60px;
}

.tm-section-underline {
  width: 50%;
}

/* 
.btn {
  padding: 11px 40px;
  border-radius: 0;
} */

.btn-primary {
  background-color: orange;
  border-color: #fff;
}

.btn-secondary {
  background-color: white;
  border-color: white;
  color: #3399ff;
}

.btn-secondary:hover {
  color: #3399ff;
  background-color: #c5c7ca;
  border-color: #c5c7ca;
}

hr {
  border-color: black;
}

.tm-hr-mb {
  margin-bottom: 46px;
}

.tm-p-mb {
  margin-bottom: 45px;
}

.tm-text-right-container {
  padding-left: 0;
}

.tm-section-text-container-7 {
  padding: 65px 140px;
}

/*  */

.tm-section-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fa-6x {
  font-size: 6em;
}

.tm-overlay-container {
  position: relative;
}

.tm-text-overlay {
  max-width: 498px;
  width: 50%;
  height: 100%;
  margin-left: 50%;
}

.tm-copyright-text {
  padding: 60px 40px 55px 70px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px;
  }
}

@media (max-width: 1200px) {
  .tm-section-title-underline {
    max-width: 350px;
  }

  .grid figure h2 {
    font-size: 1.1em;
  }

  figure.effect-duke p {
    padding: 20px;
  }

  .tm-social-icon-container {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 1100px) {
  .tm-section-underline {
    width: 60%;
  }
}

/* .tm-img-left-container,
  .tm-text-left-container {
    padding-right: none;
  }

  .tm-img-right-container {
    padding-left: none;
  } */

@media (max-width: 991px) {
  .grid {
    flex-wrap: wrap;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }

  .tm-social-icon-container {
    margin-right: 10px;
  }

  .tm-section-text-container-2 {
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .tm-section-text-container-3 {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
  }

  .tm-section-text-container-4 {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 320px;
  }

  .tm-section-image-container {
    text-align: center;
  }

  .tm-img-left-container,
  .tm-text-left-container {
    padding-right: none;
  }

  .tm-img-right-container {
    padding-left: none;
  }

  .tm-section-title-2 {
    text-align: center;
  }

  .tm-section-title-underline {
    margin-left: auto;
    margin-right: auto;
  }

  .tm-section-text-container-7 {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    padding: 64px 68px;
  }

  .tm-text-overlay {
    padding: 7%;
    width: 100%;
    margin-left: auto;
    position: relative;
    right: 0;
  }

  .tm-section-underline {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .columns-bg {
    background-image: none;
  }

  .tm-site-name-container {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .tm-section-logo {
    padding-top: 3rem;
  }

  .tm-p-1-section-1 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .tm-p-2-section-1 {
    margin-top: 50px;
  }

  .tm-p-3-section-3 {
    margin-top: 0;
  }

  #intro,
  #section_4 {
    background-color: #99ccff;
  }

  #our_place {
    background-color: white;
    padding-top: 35px;
    padding-bottom: 35px;
  }

  #gallery {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .tm-section-contact {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  #outro {
    padding-top: 60px;
  }

  #footer {
    padding-top: 0;
    padding-bottom: 15px;
  }

  .tm-contact-left {
    margin-left: 0;
  }

  .tm-section-text-container {
    padding: 50px;
  }

  .tm-section-text-container-4 {
    padding: 30px 70px 0;
    max-width: none;
  }

  .tm-section-text-container-5 {
    margin-left: 0;
  }

  .tm-section-text-container-6 {
    margin-top: 50px;
    padding: 0;
  }

  #outro .tm-text-white {
    color: #060607;
  }

  .tm-contact-left,
  .tm-contact-form-container {
    margin-left: auto;
    margin-right: auto;
  }

  .tm-contact-form-container {
    padding-bottom: 0;
  }

  .tm-btn-submit {
    color: white;
    border-color: white;
  }

  .tm-btn-submit:hover {
    color: #e9ecef;
    border-color: #e9ecef;
  }

  .form-control {
    background-color: transparent;
    color: white;
    border-color: white;
  }

  .form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */
  }

  .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
  }

  .form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
  }

  .tm-copyright-text {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px 10px 10px 0;
  }

  .tm-sm-bg-blue {
    background-color: #282c34;
  }

  .tm-section-icon-container {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .tm-section-underline {
    width: 100%;
  }

  #bg_img_31 {
    height: 500px;
  }

  .tm-p-3-section-contact {
    padding-bottom: 60px;
  }
}

@media (max-width: 687px) {
  .grid figure {
    flex: 1 0 40%;
  }
}

@media (max-width: 575px) {
  .tm-section-text-container-5 {
    padding-right: 15px;
    margin-left: 0;
    margin-right: auto;
  }

  .tm-section-text-container-6 {
    padding: 0 15px 0 0;
    margin-left: 0;
    margin-right: auto;
    max-width: 450px;
  }

  .tm-section-text-container-3,
  .tm-section-text-container-8 {
    padding: 40px 30px;
  }

  .tm-section-icon-container {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@media (max-width: 529px) {
  .grid {
    max-width: 300px;
    overflow-x: hidden;
  }

  .grid figure {
    flex: 1 0 100%;
  }

  .tm-section-text-container-4 {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  .tm-site-name-container {
    padding-left: 15%;
    padding-right: 10%;
  }

  .tm-sm-bg-white-alpha {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
  }

  .grid {
    flex-wrap: wrap;
  }

  .tm-gallery-title-col {
    padding-right: 15px;
  }

  .tm-section-text-container-4 {
    padding: 30px;
  }

  .tm-footer-text-container {
    padding-left: 25px;
  }

  .tm-contact-left {
    padding: 60px 30px;
  }

  .tm-contact-form-container {
    padding: 40px 30px 0;
  }

  #outro {
    padding-top: 40px;
    padding-left: 10px;
  }

  #bg_img_32 {
    height: 600px;
  }
}

@media (max-width: 529px) {
  /* .GAQ-mob {
    border: 2px solid #fff;
    padding: 10px;
    border-radius: 30px;
    margin-left: 10px;
  } */

  .col-2-mob h1 {
    font-size: 30px;
  }

  .col-2-mob h3 {
    font-size: 20px;
  }

  .cards-mob {
    margin-top: 10px;
  }

  /* .content-mob {
    margin-left: 20px;
    margin-right: 20px;
  } */
}

.heading {
  text-align: center;
  color: #454343;
  font-size: 30px;
  font-weight: 700;
  position: relative;
  margin-bottom: 70px;
  text-transform: uppercase;
  z-index: 999;
}

.white-heading {
  color: #ffffff;
}

.heading:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  height: 40px;
  width: 180px;
  border-radius: 4px;
  transform: translateX(-50%);
  /* background: url(img/heading-line.png); */
  background-repeat: no-repeat;
  background-position: center;
}

.white-heading:after {
  /* background: url(https://i.ibb.co/d7tSD1R/heading-line-white.png); */
  background-repeat: no-repeat;
  background-position: center;
}

.heading span {
  font-size: 18px;
  display: block;
  font-weight: 500;
}

.white-heading span {
  color: #ffffff;
}

/*-----Testimonial-------*/

.testimonial:after {
  position: absolute;
  top: -0 !important;
  left: 0;
  content: " ";
  /* background: url(img/testimonial.bg-top.png); */
  background-size: 100% 100px;
  width: 100%;
  height: 100px;
  float: left;
  z-index: 99;
}

.testimonial {
  min-height: 375px;
  position: relative;
  /* background: url(https://i.ibb.co/PTJDkgb/testimonials.jpg); */
  padding-top: 50px;
  padding-bottom: 50px;
  background-position: center;
  background-size: cover;
}

#testimonial4 .carousel-inner:hover {
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

#testimonial4 .carousel-inner:active {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

#testimonial4 .carousel-inner .item {
  overflow: hidden;
}

.testimonial4_indicators .carousel-indicators {
  left: 0;
  margin: 0;
  width: 100%;
  font-size: 0;
  height: 20px;
  bottom: 15px;
  padding: 0 5px;
  cursor: e-resize;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.testimonial4_indicators .carousel-indicators li {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  text-indent: 0;
  margin: 2px 3px;
  cursor: pointer;
  display: inline-block;
  background: #ffffff;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.testimonial4_indicators .carousel-indicators .active {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  margin: 2px 3px;
  background-color: #9dd3af;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar {
  height: 3px;
}

.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb {
  background: #eeeeee;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.testimonial4_control_button .carousel-control {
  top: 175px;
  opacity: 1;
  width: 40px;
  bottom: auto;
  height: 40px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  overflow: hidden;
  line-height: 38px;
  text-shadow: none;
  text-align: center;
  position: absolute;
  background: transparent;
  border: 2px solid #ffffff;
  text-transform: uppercase;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
  transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
}

.testimonial4_control_button .carousel-control.left {
  left: 7%;
  top: 50%;
  right: auto;
}

.testimonial4_control_button .carousel-control.right {
  right: 7%;
  top: 50%;
  left: auto;
}

.testimonial4_control_button .carousel-control.left:hover,
.testimonial4_control_button .carousel-control.right:hover {
  color: #000;
  background: #fff;
  border: 2px solid #fff;
}

.testimonial4_header {
  top: 0;
  left: 0;
  bottom: 0;
  width: 550px;
  display: block;
  margin: 30px auto;
  text-align: center;
  position: relative;
}

.testimonial4_header h4 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonial4_slide {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
}

.testimonial4_slide img {
  top: 0;
  left: 0;
  right: 0;
  width: 136px;
  height: 136px;
  margin: auto;
  display: block;
  color: #f2f2f2;
  font-size: 18px;
  line-height: 46px;
  text-align: center;
  position: relative;
  border-radius: 50%;
  box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
}

.testimonial4_slide p {
  color: #ffffff;
  font-size: 20px;
  line-height: 1.4;
  margin: 40px 0 20px 0;
}

.testimonial4_slide h4 {
  color: #ffffff;
  font-size: 22px;
}

.testimonial .carousel {
  padding-bottom: 50px;
}

.testimonial .carousel-control-next-icon,
.testimonial .carousel-control-prev-icon {
  width: 35px;
  height: 35px;
}

/* ------testimonial  close-------*/

@media only screen and (min-width: 600px) {
  .lapttop-res-lott {
    width: 80%;
    margin-left: 120px;
  }

  .card-container,
  .front,
  .back {
    width: 23rem;
    height: 23rem;
  }

  .card-container {
    margin: 2rem;
  }

  #contact-mobile {
    display: none;
  }
}

.aliceCar {
  padding: 20px;
  padding-top: 40px;
}

.aliceCarclient {
  padding: 20px;
  padding-top: 90px;
  background-color: #282c34;
}

/* .item {
  display: inline-block;
  margin-right: 20px;
  text-align: center;
} */
.center {
  text-align: center;
}

@media only screen and (max-width: 600px) {

  /* .mobile-res-lott{
    width: 0%;
    margin-left: 120px;
  } */
  .card-container,
  .front,
  .back {
    width: 15rem;
    height: 17rem;
  }

  .card-container {
    margin: 2rem;
  }

  /* #contact-laptop {
    display: none;
  } */

  /* .not-for-mobile {
    display: none;
  } */
}

@media only screen and (min-width: 600px) {
  .not-for-laptop {
    display: none;
  }
}

/* .row .col-md-12{
  padding-right: none;
} */
.row {
  --bs-gutter-x: -0.5rem;
  --bs-gutter-y: 0;
}

.section_padding {
  padding: 2rem 0;
}

.heading_style {
  text-align: center;
  font-weight: 600;
  color: orange;
  font-family: "Poppins";
  margin: 2rem 0;
}

.square-holder {
  padding: 21px;
  border: 1px solid #cecece;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: #f1f1f1;
  min-height: 200px;
}

.square-holder img {
  max-width: 100%;
  filter: grayscale(100%);
  transition: all 0.3s;
}

.square-holder:hover img {
  filter: none;
}

/* .para {
  width: 600px;
} */


/* .client {
  --bs-gutter-x: -0.5rem;
  --bs-gutter-y: 0;
} */

@media (max-width: 700px) {

  /* .client {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 0;
  } */
  .para {
    width: 100%;
  }

  .testo_img {
    margin-left: 30px;
  }
}

.contact-card {
  background-color: var(--contact-card-back);
  margin: 20px;
  padding: 30px 0px;
  border-radius: 6px;
  border: 1px solid gray;
}

.contact-card:hover .contact_content h2 {
  color: orange;
}

.theme-switch-btn {
  /* position: fixed;
  bottom: 2%;
  left: 2%; */
  /* background-color: var(--switch-back-color); */
  background-color:transparent;
  /* padding: 10px; */
  border-radius: 3px;
  border: none;
  z-index: 33333;

  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.theme-switch-btn img {
  width: 40px;
}

.typer-back {
  /* background-color: var(--type-back); */
  width: fit-content;
  border-radius: 20px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}
.typer-back h3{
  padding: 0px 0px;
  color: var(--LinksColor);
}

.para-testo {
  width: 600px;
  color: var(--para-color);
}

@media (max-width:600px) {
  .para-testo {
    width: 100%;
  }
}

.fa-money-check-alt:before {
  content: "\f53d";
  color: var(--services-icon);
}

.fa-clock:before {
  content: "\f017";
  color: var(--services-icon);
}

.fa-signal:before {
  content: "\f012";
  color: var(--services-icon);
}

#choose-us-section {
  background-color: var(--choose-us-back);
}

#outlead-about {
  background-color: var(--about-back-outlead);
  padding: 0px 20px;
}

@media (max-width:600px) {
  #outlead-about {
    background-color: var(--about-back-outlead);
    padding: 0px 20px;
  }
}
.contact-info{
  background-color:#22252b;
}

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal .ant-modal-content{
  background-color: var(--back-color);
}
/* Width */
::-webkit-scrollbar {
  width: 10px; /* width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: orange; /* color of the scrollbar handle */
  transition: .3s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: white; /* color of the scrollbar handle on hover */
  transition: .3s;
}


.card_content ul{
  padding: 0px;
}